<template>
  <single-page>
    <article v-if="lang == 'zh-CN'" lang="zh-CN">
      <h1>使命</h1>
      <section>
        <p>MEKE的使命是将去中心化衍生品交易所推向更多交易者，让加密货币爱好者们使用上更安全、更高效的去中心化衍生品交易所，让去中心化交易协议有着和中心化衍生品一样的体验感。</p>
      </section>
      <section>
        <p>加密货币本身就是安全、透明，公开、公正的代言词，而由于技术限制，人们只能退而求其次，使用中心化交易所。中心化衍生品交易不能满足加密货币安全、透明的要求，用户的资产在其中面临着各类暗箱操作的风险。</p>
      </section>
      <section>
        <p>MEKE要改变这一现状，通过团队对市场的研究及多年的技术积累，为用户打造出更适合加密圈人使用的，使用区块链技术构建的加密货币衍生品去中心化交易协议。让全球加密货币用户重启自己的交易生涯。</p>
      </section>
    </article>
    <article v-else  lang="en-US">
      <h1>Our Missions</h1>
      <section>
        <p>MEKE’s mission is to promote the decentralized derivatives exchange to more traders, to help cryptocurrency enthusiasts use a safer and more efficient decentralized derivatives exchange, and to enable the decentralized trading protocol to provide the same experience as the centralized derivatives. </p>
      </section>
      <section>
        <p>Cryptocurrency represents security, transparency, openness, and fairness. Nevertheless, people have to fall back on centralized exchanges due to technical limitations. As centralized derivatives trading cannot meet the requirements for the security and transparency of the cryptocurrency, the users’ assets may face the risks of various black case works there. </p>
      </section>
      <section>
        <p>MEKE wishes to change this status. By relying on the team’s research on the market and years of technical experience, it has created a decentralized trading protocol for cryptocurrency derivatives based on blockchain technology, which is more suitable for users in the cryptocurrency community. MEKE enables cryptocurrency users across the world to reinitiate their trading. </p>
      </section>
    </article>
  </single-page>
</template>
<script>
import SinglePage from './components/singlePage'
export default {
  components: {
    SinglePage
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    }
  }
};
</script>
<style lang="less" scoped></style>